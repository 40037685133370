package client

import kotlinext.js.jso
import kotlinx.browser.*
import kotlinx.html.*
import kotlinx.html.dom.*
import react.dom.render

fun main() {
    document.getElementById("root")?.let {
        render(it) {
            App(props = jso(), handler = {})
        }
    }
    document.body!!.querySelector("#root")!!.append {
        h1 {
            +"Welcome to Kotlin/JS PWA!"
        }
        p {
            +"v0.0.4"
        }
        p {
            +"Fancy joining this year's "
            a("https://kotlinconf.com/") {
                +"KotlinConf"
            }
            +"?"
        }
    }
}
